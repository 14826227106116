import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import ProfileService from "../services/profile.service";
import PageTitle from "../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../redux/reducers/user/user.thunk";
import { loadUserProfile } from "../redux/reducers/profile/profile.thunk";
import {
  convertToDevanagariTime,
  convertToDevanagariDate,
  convertDate,
} from "../helpers/utility";
import Loader from "../shared-components/loader";
import Urls from "../constants/urls";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.error = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const MyProfile = (props) => {
  const dispatch = useDispatch();

  const { userProfile } = useSelector(
    (state) => state.profile
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadUserProfile());
  }, [dispatch]);

  async function displayRazorpay() {
    setLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("कृपया थोड्या वेळाने प्रयत्न करा");
      setLoading(false);
      return;
    }

    if(userProfile !== null) {
      if(userProfile.data.razorpay_order_id !== null) {
        const options = {
          key: Urls.RAZORPAY_API_KEY,
          amount: "40000",
          currency: "INR",
          name: "वीरशैव हितसंवर्धक मंडळ, यवतमाळ",
          description: "वधु-वर ऑनलाईन परिचय मेळावा २०२१ नोंदणी",
          order_id: userProfile.data.razorpay_order_id,
          handler: function (response) {
            let rzResponse = {};
            rzResponse.razorpay_payment_id = response.razorpay_payment_id;
            rzResponse.razorpay_order_id = response.razorpay_order_id;
            rzResponse.razorpay_signature = response.razorpay_signature;
            ProfileService.verifyPayment(rzResponse)
              .then((res) => {
                setLoading(false);
                props.history.push("/thank-you");
              })
              .catch((error) => {
                // console.log(error.message);
                setLoading(false);
                props.history.push("/sorry");
              });
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        ProfileService.createOrder()
      .then((orderData) => {
        const options = {
          key: Urls.RAZORPAY_API_KEY,
          amount: "40000",
          currency: "INR",
          name: "वीरशैव हितसंवर्धक मंडळ, यवतमाळ",
          description: "वधु-वर ऑनलाईन परिचय मेळावा २०२१ नोंदणी",
          order_id: orderData.data.data.order_id,
          handler: function (response) {
            let rzResponse = {};
            rzResponse.razorpay_payment_id = response.razorpay_payment_id;
            rzResponse.razorpay_order_id = response.razorpay_order_id;
            rzResponse.razorpay_signature = response.razorpay_signature;
            ProfileService.verifyPayment(rzResponse)
              .then((res) => {
                setLoading(false);
                props.history.push("/thank-you");
              })
              .catch((error) => {
                setLoading(false);
                props.history.push("/sorry");
              });
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((error) => {
        // console.log(error.response.data);
      });
      }
    } else {
      alert("कृपया थोड्या वेळाने प्रयत्न करा");
    }
  }

  const handleClick = () => {
    dispatch(userLogout(props));
  };

  return (
    <>
      <PageTitle title="माझे प्रोफाइल" align="center" />
      {loading && <Loader />}
      <section>
        <Container>
          <Row>
            <Col md={8} className="mb-3">
              <div className="info-container">
                <h4 className="section-title pb-3">
                  {userProfile !== null ? userProfile.data.gender : ""} माहिती
                </h4>
                {userProfile !== null ? (
                  <>
                  {
                    (userProfile.data.title === null || userProfile.data.title === '' || userProfile.data.photo === null) &&
                    <div className="fill-profile-info">
                      <h3>तुमची प्रोफाइल माहिती अपूर्ण आहे. कृपया प्रोफाइल माहिती पूर्ण करा आणि पेमेंट करा.</h3>
                    </div>
                  }
                  
                  
                  <div className="user-profile">
                    <div className="user-image">
                      <div className="user-no">&nbsp;</div>
                      {userProfile.data.photo != undefined ? (
                        <img
                          src={`${Urls.IMAGE_BASE_URL}/${userProfile.data.photo}`}
                          className="img-fluid"
                          alt="profile"
                        />
                      ) : (
                        ""
                      )}
                      <h2>
                        {userProfile.data.last_name} {userProfile.data.title}
                        {userProfile.data.first_name}
                      </h2>
                    </div>
                    <div className="user-details">
                      <div className="user-no">&nbsp;</div>
                      <div className="vh-flex user-name">
                        <p className="vh-flex-title">नाव</p>
                        <p className="vh-flex-value">
                          {userProfile.data.last_name} {userProfile.data.title}
                          {userProfile.data.first_name}{" "}
                          {userProfile.data.middle_name}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">जन्म ता./वेळ</p>
                        <p className="vh-flex-value">
                          {userProfile.data.dob != undefined
                            ? convertToDevanagariDate(userProfile.data.dob)
                            : ""}
                          |{userProfile.data.day_time}{" "}
                          {userProfile.data.birth_time != undefined
                            ? convertToDevanagariTime(
                                userProfile.data.birth_time
                              )
                            : ""}{" "}
                          मि.|जन्मगाव: {userProfile.data.birth_place}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">&nbsp;</p>
                        <p className="vh-flex-value">
                          उंची: {userProfile.data.height_foot}.
                          {userProfile.data.height_inch}" ।{" "}
                          {userProfile.data.varna} । रक्तगट:{" "}
                          {userProfile.data.blood_group}{" "}
                          {userProfile.data.divorced === "true"
                            ? "| घटस्फोटित"
                            : ""}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">शिक्षण</p>
                        <p className="vh-flex-value">
                        {userProfile.data.profession} {userProfile.data.education}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">नो./व्यवसाय</p>
                        <p className="vh-flex-value">
                          {userProfile.data.occupation} {userProfile.data.address}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">वार्षिक उत्पन्न:</p>
                        <p className="vh-flex-value">
                          {userProfile.data.annual_income}
                        </p>
                      </div>
                      
                      <div className="vh-flex">
                        <p className="vh-flex-title">अपेक्षा:</p>
                        <p className="vh-flex-value">
                          {userProfile.data.expectations}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">भाऊ विवाहित</p>
                        <p className="vh-flex-value">
                          {userProfile.data.married_brothers} &nbsp; अविवाहित:{" "}
                          {userProfile.data.unmarried_brothers} / बहीण विवाहित:{" "}
                          {userProfile.data.married_sisters} &nbsp; अविवाहित:
                          {userProfile.data.unmarried_sisters}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">वडिलांचे नाव</p>
                        <p className="vh-flex-value">
                          {userProfile.data.parent_first_name}{" "}
                          {userProfile.data.parent_middle_name}{" "}
                          {userProfile.data.parent_last_name}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">पत्ता</p>
                        <p className="vh-flex-value">
                          {userProfile.data.parent_address} मो.
                          {userProfile.data.parent_mobile}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">मामकूळ</p>
                        <p className="vh-flex-value">
                          {userProfile.data.mama_first_name},{" "}
                          {userProfile.data.mama_address}, मो.
                          {userProfile.data.mama_mobile}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="vh_post_details">
                    <h3>माहिती पुस्तिका खाली दिलेल्या पत्यावर पाठवली जाईल</h3>
                    <p>नाव: {userProfile.data.post_last_name}{" "}{userProfile.data.post_first_name}{" "}{userProfile.data.post_middle_name}<br/>पत्ता: {userProfile.data.post_address}<br/>{userProfile.data.post_district === "महाराष्ट्रा बाहेरील" ? '' : (userProfile.data.post_district + ' - ')}{userProfile.data.post_pincode}<br/> 
मोबाइल नं.: {userProfile.data.post_mobile}</p>
                    </div>
                    </>
                ) : (
                  <p className="text-danger">लोडींग...</p>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div className="bg-white p-4">
                <h4 className="section-title pb-3">प्रोफाइलची माहिती</h4>
                {userProfile != null ? (
                  <div>
                    <p>
                      पेमेंट स्टेटस:{" "}
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {userProfile.data.payment_status}
                      </span>
                    </p>
                    <p>
                      नोंदणी दिनांक:{" "}
                      {userProfile.data.created_at != undefined
                        ? convertDate(userProfile.data.created_at)
                        : ""}
                    </p>
                    <p>
                      प्रोफाइल बदल:{" "}
                      {userProfile.data.updated_at != undefined
                        ? convertDate(userProfile.data.updated_at)
                        : ""}
                    </p>
                  </div>
                ) : (
                  <p className="text-danger">लोडींग...</p>
                )}
                {userProfile != null &&
                <>
                <Link to={"/upload-image/"+userProfile.data.id} className="btn btn-danger w-100 mb-3">
                  फोटो बदला
                </Link>
                <Link to={"/edit-details/"+userProfile.data.id} className="btn btn-danger w-100 mb-3">
                  एडिट प्रोफाइल
                </Link>
                </>}
                {userProfile != null ? (
                  userProfile.data.payment_status != undefined ?
                  (userProfile.data.payment_status != 'Paid' && userProfile.data.first_name != null && userProfile.data.photo != null) ? (
                    <Button className="btn btn-success w-100 mb-3" onClick={displayRazorpay}>
                      येथे पेमेंट करा
                    </Button>
                  ) : '' : (
                    ""
                  )
                ) : (
                  ""
                )}
                <Button
                  onClick={handleClick}
                  className="btn btn-warning w-100 mb-3"
                >
                  लॉग आऊट
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MyProfile;
